/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import {
	type FC,
	forwardRef,
	useMemo,
	type CSSProperties,
	type DetailedHTMLProps,
	type ButtonHTMLAttributes,
} from 'react';
import { N0, N50A, N60A, P200, P300, P400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { type DefaultSpotlightCardProps, type SpotlightCardType } from './types';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

const borderRadius = 3;

const containerStyles: CSSProperties = {
	color: token('color.text.inverse', N0),
	boxShadow: token('elevation.shadow.overlay', `0 4px 8px -2px ${N50A}, 0 0 1px ${N60A}`),
	backgroundColor: token('color.background.discovery.bold', P300),
	borderRadius: `${borderRadius}px`,
	overflow: 'auto',
};

const bodyStyles: CSSProperties = {
	display: 'flex',
	flexDirection: 'column',
	padding: `${token('space.200', '16px')} ${token('space.250', '20px')}`,
};

// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
const headingStyles = css({
	fontSize: '1.42857em',
	fontStyle: 'inherit',
	lineHeight: 1.2,
	fontWeight: 500,
	letterSpacing: '-0.008em',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: 28,
	color: 'inherit',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:first-child': {
		marginTop: 0,
	},
});

const headerStyles: CSSProperties = {
	display: 'flex',
	paddingBottom: token('space.100', '8px'),
	alignItems: `baseline`,
	justifyContent: `space-between`,
};

const footerStyles: CSSProperties = {
	display: 'flex',
	paddingTop: token('space.100', '8px'),
	alignItems: 'center',
	justifyContent: 'space-between',
};

// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
const actionItemStyles = (index: number) =>
	css({
		margin: `0 ${token('space.050', '4px')}`,
		cursor: 'pointer',
		alignItems: 'baseline',
		borderWidth: '0px',
		borderRadius: '3px',
		boxSizing: 'border-box',
		display: 'inline-flex',
		fontSize: 'inherit',
		fontStyle: 'normal',
		fontFamily: 'inherit',
		fontWeight: 500,
		maxWidth: '100%',
		position: 'relative',
		textAlign: 'center',
		textDecoration: 'none',
		transition:
			'background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s',
		whiteSpace: 'nowrap',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		background: index
			? token('color.background.discovery.bold', P300)
			: token('color.background.inverse.subtle', P400),
		height: '2.28571em',
		lineHeight: '2.28571em',
		padding: '0px 10px',
		verticalAlign: 'middle',
		width: 'auto',
		justifyContent: 'center',
		color: token('color.text.inverse', N0),
		'&:hover': {
			backgroundColor: token('color.interaction.hovered', P200),
		},
		'&:active': {
			backgroundColor: token('color.interaction.pressed', P200),
		},
	});

const ActionItem: FC<
	DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & { index: number }
	// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
> = ({ index, ...rest }) => <button css={actionItemStyles(index)} {...rest} />;

const actionItemContainerStyles: CSSProperties = {
	display: 'flex',
	margin: `${token('space.negative.050', '-4px')}`,
	flexDirection: 'row-reverse',
};

export const DefaultSpotlightCard: SpotlightCardType = forwardRef<
	HTMLDivElement,
	DefaultSpotlightCardProps
>(
	(
		{ image, heading, actions, actionsBeforeElement, width = 300, cardTestId, content, id },
		ref,
	) => {
		const renderedActions = useMemo(
			() =>
				actions?.map(({ text, onClick }, index) => (
					<ActionItem index={index} onClick={onClick} key={`${text}-${index}`}>
						{text}
					</ActionItem>
				)),
			[actions],
		);

		const renderHeader = !!heading;
		const renderFooter = !!actionsBeforeElement || !!actions;

		return (
			<div
				ref={ref}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={{ ...containerStyles, width: `${width}px` }}
				data-testid={cardTestId}
			>
				{typeof image === 'string' ? <img src={image} alt="" /> : image}
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
				<div style={bodyStyles} id={id}>
					{renderHeader && (
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						<div style={headerStyles}>
							<div id="nudge-spot-light-card-heading" css={headingStyles}>
								{heading}
							</div>
						</div>
					)}
					{content}
					{renderFooter && (
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						<div style={footerStyles}>
							{actionsBeforeElement || <span />}
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
							<div style={actionItemContainerStyles}>{renderedActions}</div>
						</div>
					)}
				</div>
			</div>
		);
	},
);

export default DefaultSpotlightCard;
